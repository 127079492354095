import React, { useState, useEffect } from 'react';

import backArrow from '../../assets/icons/backArrow.svg';
import { useTranslation } from "react-i18next";

export function MonthDayFilter({ events, setFilteredEvents }) {
     const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [isDayView, setIsDayView] = useState(false);

    useEffect(() => {
        // On component mount, filter for no month
        filterByMonth(-1);
    }, [events, setFilteredEvents]);

    const filterByMonth = (month) => {
        setSelectedMonth(month);
        setIsDayView(false);

        const filtered = events.filter(event => {
            const eventStart = new Date(event.data_inizio);
            const eventEnd = new Date(event.data_fine || event.data_inizio);

            return (
                eventStart.getMonth() <= month && eventEnd.getMonth() >= month
            );
        });
        setFilteredEvents(filtered);
    };

    const handleDayClick = (day) => {
        setSelectedDay(day);
        const filtered = events.filter(event => {
            const eventStart = new Date(event.data_inizio);
            const eventEnd = new Date(event.data_fine || event.data_inizio);

            return (
                (eventStart.getDate() === day && eventStart.getMonth() === selectedMonth) ||
                (eventEnd.getDate() === day && eventEnd.getMonth() === selectedMonth)
            );
        });
        setFilteredEvents(filtered);
    };

    const handleMonthClick = (month) => {
        filterByMonth(month);
        setIsDayView(true);
    };

    const backToMonthView = () => {
        setIsDayView(false);
    };

    const getDaysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const daysInMonth = getDaysInMonth(new Date().getFullYear(), selectedMonth);

    const { t } = useTranslation();
    const MONTHS = [`${t('Gennaio')}`, `${t('Febbraio')}`, `${t('Marzo')}`, `${t('Aprile')}`, `${t('Maggio')}`, `${t('Giugno')}`, `${t('Luglio')}`, `${t('Agosto')}`, `${t('Settembre')}`, `${t('Ottobre')}`, `${t('Novembre')}`, `${t('Dicembre')}`,];
    const DAYS_OF_WEEK = [`${t('lunedip')}`, `${t('martedip')}`, `${t('mercoledip')}`, `${t('giovedip')}`, `${t('venerdip')}`, `${t('sabatop')}`, `${t('domenicap')}`,];


    return (
        <div className={'h-full overflow-x-hidden'}>
            <div className={'bg-white'}>
                {isDayView && <button className={'p-2 bg-rtllBlue text-white rounded-[10px] w-[70px] font-semibold mb-5 flex font-roboto_slab text-[12px] h-[5vh]'} onClick={backToMonthView}><img className={'mr-2 w-2'} src={backArrow}/> {t('mesi')}</button>}
                {isDayView && <p className={'font-semibold text-rtllBlue mb-3 font-roboto_slab text-sm'}>{MONTHS[selectedMonth]}</p>}
            </div>

            <div className="month-day-filter h-full overflow-auto overflow-x-hidden">
                {!isDayView && MONTHS.map((month, i) => (
                    <button
                        key={i}
                        className={`calendar-day-button font-semibold font-roboto_slab border border-rtllBlue mb-5 h-[70px] w-[70px] rounded-[10px] text-rtllBlue text-sm ${i === selectedMonth ? 'bg-rtllBlue text-white' : 'bg-white'}`}
                        onClick={() => handleMonthClick(i)}
                    >
                        {month}
                    </button>
                ))}
                {isDayView && Array.from({ length: daysInMonth }, (_, i) => i + 1).map(day => {
                    const date = new Date(new Date().getFullYear(), selectedMonth, day);
                    const dayOfWeek = DAYS_OF_WEEK[date.getDay()];

                    return (
                        <div key={day} className="calendar-day-container">
                            <button
                                onClick={() => handleDayClick(day)}
                                className={`calendar-day-button border font-semibold font-roboto_slab border-rtllBlue mb-5 h-[70px] w-[70px] rounded-[10px] text-rtllBlue ${day === selectedDay ? 'bg-rtllBlue text-white' : 'bg-white'}`}
                            >
                                {day}
                                <p className="calendar-day-label">{dayOfWeek}</p>
                            </button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
