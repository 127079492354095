import React, { useState } from 'react';
import SearchBlue from "../../assets/icons/searchBlue.svg";
import {useTranslation} from "react-i18next";

export const SearchInput = (props) => {
    const {t} = useTranslation()
    const [searchTerm, setSearchTerm] = useState('');

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        props.onSearch(event.target.value);
    };

    return (
        <div className={'px-0 h-[50px] -mt-[45px] relative w-full mb-8'}>
            <img src={SearchBlue} alt={'SearchBlue'} className={'absolute left-5 top-1/2 transform -translate-y-1/2 z-20'} />
            <input type={'text'} placeholder={t('cerca')} value={searchTerm} onChange={handleInputChange} className={'text-left w-full h-[50px] px-5 pl-14 font-semibold relative z-10 rounded-[10px] shadow-rtllShadow'}/>
        </div>

    );
}

