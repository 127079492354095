import {BackButton} from "../components/UI/BackButton";
import React from "react";
import logo from "../assets/logoBBSextended.png"
import {useTranslation} from "react-i18next";
export const Crediti = () => {

    const {t} = useTranslation()

    return (
        <section className="bg-rtllBlue h-screen text-left w-full">
            <div className={"flex flex-wrap h-[16vh] content-center relative z-10 p-5"}>
                <div className={"flex flex-wrap items-center"}>
                    <BackButton sfondo={"false"} />
                    <span className={"text-white font-semibold text-[20px] ml-4"}>{t('crediti')}</span>
                </div>

            </div>
            <p className={"text-white font-semibold text-[16px] ml-4 mr-4"}>
                {t('crediti1')} <br/></p>
                <p className={"text-white font-semibold text-[16px] ml-4 mt-4 ml-4 mr-4"}>

                    <strong>BBS tecnologia di buon senso</strong> <br/> <a className={'underline'} href={'https://www.bbsitalia.com'}>www.bbsitalia.com</a></p>

            <a href={'https://www.bbsitalia.com'}><img src={logo} className={'ml-4 mt-5'}/></a>
        </section>
    );
};